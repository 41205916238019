import React from 'react'
import { JSONEditor } from '@json-editor/json-editor'

class JsonEditor extends React.PureComponent {
  timeout = undefined

  constructor(props) {
    super(props)
    this.state = {
      dirty: false,
    }
  }

  componentDidMount() {
    const { value, options } = this.props

    console.log(value)

    const opts = {
      ...options,
    }

    if (opts.schema == null) {
      opts.schema = {}
    }

    if (opts.theme == null) {
      opts.theme = 'bootstrap4'
    }

    const mergedOptions = {
      ...opts,
      // onChange: this.handleChange,
      // onValidationError: this.handleError,
    }

    this.editor = new JSONEditor(this.div, mergedOptions)

    this.editor.on('ready', () => {
      // Now the api methods will be available
      // editor.validate();
      // const defaultValue = this.getValue();
      // console.log(' --- ' , defaultValue);
      if (value != null) {
        this.setValue(value)
      }
    })

    this.editor.on('change', (e) => {
      console.log('e : ', e)
      this.handleChange()
    })
  }

  componentDidUpdate(prevProps /* , prevState */) {
    const { onDirty, value } = this.props
    const { dirty, controllingFocus } = this.state

    if (dirty === true && controllingFocus) {
      onDirty()
    }

    if (dirty === true && !controllingFocus) {
      this.timeout = setTimeout(() => {
        this.setState({
          dirty: false,
        })
      }, 100)
    }

    if (controllingFocus) {
      clearTimeout(this.timeout)
    }

    if (prevProps.value !== value) {
      this.setValue(value)
      setTimeout(() => {
        this.setState({
          dirty: false,
        })
      }, 1)
    }
  }

  componentWillUnmount() {
    this.editor.destroy()
    delete this.editor
    clearTimeout(this.timeout)
  }

  setValue = (value) => {
    if (value != null) {
      if (typeof value === 'string') {
        const obj = JSON.parse(value)
        this.editor.setValue(obj)
      } else {
        this.editor.setValue(value)
      }
    } else {
      this.editor.setValue({})
    }
    if (this.div != null) {
      this.div.value = value
      this.div.setAttribute('value', value)
    }
  }

  getValue = () => {
    const vObj = this.editor.getValue()
    const val = JSON.stringify(vObj)
    if (this.div != null) {
      this.div.value = val
      this.div.setAttribute('value', val)
    }
    return val
  }

  handleChange = () => {
    const { onChange } = this.props

    const value = this.getValue()

    // console.log('validate : ', this.editor.validate())

    if (onChange != null) {
      onChange(value)
    }
    if (this.div != null) {
      this.div.value = value
      this.div.setAttribute('value', value)
    }
  }

  handleFocus = () => {
    this.setState({
      controllingFocus: true,
    })
  }

  handleBlur = () => {
    this.setState({
      controllingFocus: false,
    })
  }

  handleError = (e) => {
    const { onError } = this.props

    console.log('handleError : ', e)

    if (onError != null) {
      onError({
        hasError: e.length > 0,
        errors: e,
      })
    }
  }

  render() {
    const { className, style } = this.props

    return (
      <div
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        className={className}
        style={style}
        ref={(div) => {
          this.div = div
        }}
      />
    )
  }
}

export default JsonEditor
