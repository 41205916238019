import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dropdown, Avatar, Badge } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const { t } = useTranslation()
  const [count, setCount] = useState(0)

  const logout = (e) => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    // setCount(count + 1)
    // count가 0이면 badge가 표시안됨.
    setCount(0)
  }

  const items = [
    {
      label: (
        <React.Fragment>
          <strong>
            {t('topBar.profileMenu.hello')}, {user.name || 'Anonymous'}
          </strong>
          {/*
          <div>
            <strong className="mr-1">
              {t('topBar.profileMenu.billingPlan')}:{' '}
            </strong>
            Professional
          </div>
          */}
          <div>
            <strong>{t('topBar.profileMenu.role')}: </strong>
            {user.role || '—'}
          </div>
        </React.Fragment>
      ),
      key: 'name',
    }, // remember to pass the key prop
    {
      label: (
        <div>
          <strong>{t('topBar.profileMenu.email')}: </strong>
          {user.email || '—'}
          {/*
          <br />
          <strong>
            {t('topBar.profileMenu.phone')}:{' '}
          </strong>
          {user.phone || '—'}
          */}
        </div>
      ),
      key: 'email',
    }, // which is required
    {
      type: 'divider',
    },
    {
      label: (
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          {t('topBar.profileMenu.logout')}
        </a>
      ),
      key: 'logout',
    },
  ]

  const menu = {
    items,
    selectable: false,
  }

  return (
    <Dropdown menu={menu} trigger={['click']} onOpenChange={addCount}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
