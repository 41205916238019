import React from 'react'
import styles from './style.module.scss'

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerInner}>
        <a
          href="https://www.rework.so"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.logo}
        >
          RE:WORK ENTERPRISE
          <span />
        </a>
        <br />
        <p className="mb-0">
          Copyright © 2013-2023 9Folders, Inc. |{' '}
          <a href="https://rework.so/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
