import { notification } from 'antd'
import store from 'store'

import { restClient } from 'myNet'

export default restClient

const validateOTPURL = '/__admin/auth/validate/otp'
const sendEmailURL = '/__admin/auth/send/otp'
const loginURL = '/__admin/auth/login/otp'

export async function validateOTP() {
  return restClient
    .get(validateOTPURL)
    .then((response) => {
      console.log('res = ', response)
      return response
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function reqOtpCode(email) {
  return restClient
    .post(sendEmailURL, { email })
    .then((res) => {
      if (process.env.REACT_APP_TARGET.indexOf('test') > -1) {
        if (res.data.otp != null) {
          // const log = getLogger({ name: '9folders' })
          console.log(`OTP CODE : ${res.data.otp}`)
        }
      }
      return {
        success: true,
        otp_expires_in: res.data.otp_expires_in,
      }
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function loginAdmin(email, loginCode) {
  return restClient
    .post(loginURL, { otp: loginCode })
    .then((res) => {
      store.set('app.user.token', res.data.token)
      store.set('app.user.refreshExpiresIn', res.data.refresh_expires_in)
      store.set('app.user.refreshToken', res.data.refresh_token)

      return true
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}
