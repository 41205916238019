/* eslint-disable global-require */
import { getEnvParam } from 'env'

const configMap = {}

export default function serverConfigFunc() {
  const serverType = getEnvParam('serverType') || ''

  if (configMap[serverType] == null) {
    const config = {}

    // serverType.
    // - REWORK-ONLINE (ex: api.rework.so) - master admin.
    // - REWORK-ENTERPRISE-ONLINE (ex: api.rework.so) - enterprise admin.
    // - REWORK-ENTERPRISE-PORTAL (ex: api.appurityconnect.com) - sub master admin.
    // - REWORK-ENTERPRISE (ex: appdev.kolon.com) - admin

    switch (serverType) {
      case 'REWORK-ONLINE':
        config.menu = require('./rework-online/menu').default
        config.router = require('./rework-online/router').default
        config.config = require('./rework-online/config').default
        break
      case 'REWORK-ENTERPRISE-ONLINE':
        config.menu = require('./rework-enterprise-online/menu').default
        config.router = require('./rework-enterprise-online/router').default
        config.config = require('./rework-enterprise-online/config').default
        break
      case 'REWORK-ENTERPRISE-PORTAL':
        config.menu = require('./rework-enterprise-portal/menu').default
        config.router = require('./rework-enterprise-portal/router').default
        config.config = require('./rework-enterprise-portal/config').default
        break
      case 'REWORK-ENTERPRISE':
        config.menu = require('./rework-enterprise/menu').default
        config.router = require('./rework-enterprise/router').default
        config.config = require('./rework-enterprise/config').default
        break
      default:
        config.menu = () => []
        config.router = () => []
        config.config = {
          defaultPage: '/workspace/list',
        }
    }

    configMap[serverType] = config
  }

  return configMap[serverType]
}
