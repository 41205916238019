import { notification } from 'antd'
import store from 'store'
// import { ApolloError } from '@apollo/client'

import { store as reduxStore } from 'index'
import { setEnvParam } from 'env'

import { clientForRefreshToken } from 'myNet'

// eslint-disable-next-line import/no-cycle
import Ngql from 'gql/ninegql'
import { parseJwt, processError } from 'utils'

const mutationLogin = Ngql.Mutation('tokenAuth', {
  tokenAuth: Ngql.Node(
    {
      token: true,
      refreshExpiresIn: true,
      refreshToken: true,
      // payload: true
    },
    null,
    false,
    {
      email: Ngql.Var('email', 'String!'),
      password: Ngql.Var('password', 'String!'),
      serverType: Ngql.Var('serverType', 'String'),
      serverUri: Ngql.Var('serverUri', 'String'),
    },
  ),
})

const mutationUpdataToken = Ngql.Mutation('refreshToken', {
  refreshToken: Ngql.Node(
    {
      token: true,
      refreshExpiresIn: true,
      refreshToken: true,
      // payload: true
    },
    null,
    false,
    {
      // refreshToken: Ngql.Var('refreshToken', 'String!'),
    },
  ),
})

const updateInfo = {
  isUpdating: false,
  currentQuery: null,
  waitingReq: [],
}

function updateToken() {
  const refreshToken = store.get('app.user.refreshToken')
  let ret = null
  if (refreshToken != null) {
    if (updateInfo.isUpdating === false) {
      store.remove('app.user.token')
      updateInfo.isUpdating = true
      // store.set('app.user.token', refreshToken)
      updateInfo.currentQuery = Ngql.GQLObj(mutationUpdataToken, {
        // vars: {
        //   refreshToken,
        // },
      })
        .mutate({}, clientForRefreshToken)
        .then((res) => {
          store.set('app.user.token', res.data.refreshToken.token)
          store.set('app.user.refreshExpiresIn', res.data.refreshToken.refreshExpiresIn)
          store.set('app.user.refreshToken', res.data.refreshToken.refreshToken)

          updateInfo.waitingReq.forEach((promise) => {
            promise.resolve(res.data.refreshToken.token)
          })
          updateInfo.waitingReq = []

          updateInfo.isUpdating = false
          updateInfo.currentQuery = null
          return res.data.refreshToken.token
        })
        .catch(() => {
          updateInfo.waitingReq.forEach((promise) => {
            promise.reject()
          })
          updateInfo.waitingReq = []

          updateInfo.isUpdating = false
          updateInfo.currentQuery = null

          reduxStore.dispatch({
            type: 'user/LOGOUT',
          })
        })

      ret = updateInfo.currentQuery
    } else {
      const retPromise = new Promise((resolve, reject) => {
        updateInfo.waitingReq.push({
          resolve,
          reject,
        })
      })
      ret = retPromise
    }
  }
  return ret
}

function callUpdateToken(uri, options) {
  let retPromise = null
  let ret = false

  const updateHandle = updateToken()
  if (updateHandle != null) {
    retPromise = new Promise((resolve, reject) => {
      updateHandle
        .then((token) => {
          console.log(' new token : ', token)
          if (token != null) {
            options.headers.authorization = `JWT ${token}`
            window.fetch(uri, options).then((res2) => {
              resolve(res2)
            })
          }
        })
        .catch(() => {
          reject()
        })
    })
    ret = true
  }

  return {
    retPromise,
    ret,
  }
}

export function customFetchWithToken(uri, options) {
  let isTokenEmpty = null
  const token = store.get('app.user.token')
  if (token == null || token.trim().length === 0) {
    // throw new Error('authLink - empty token..!')
    isTokenEmpty = true
  }

  return customFetch(uri, options, isTokenEmpty)
}

export function customFetch(uri, options, isTokenEmpty) {
  console.log(' ==== customFetch ', uri, options, isTokenEmpty, this)
  // This reference to the refreshingPromise will let us check later on if we are executing getting the refresh token.
  // Create initial fetch, this is what would normally be executed in the link without the override

  if (isTokenEmpty === true) {
    console.log(' ==== customFetch - token is Empty~! ')
    const callRet = callUpdateToken(uri, options)
    return callRet.retPromise
  }

  const initialRequest = fetch(uri, options)

  let responseData = null
  // The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
  // Here we return the initialRequest promise
  return initialRequest
    .then((response) => {
      const ret = response.clone().json()
      console.log(' ==== fetch result ', response, ret)
      responseData = response
      return ret
    })
    .then((error) => {
      if (responseData.status >= 400 && error && error.code !== 200) {
        let ret = null
        processError(error, (code, message) => {
          if (code === 401 || message.indexOf('Signature has expired') > -1) {
            console.log(' !!! Signature has expired ')
            ret = callUpdateToken(uri, options)
            if (ret.retPromise == null) {
              notification.warning({
                message: code,
                description: message,
              })
            }

            // } else if (message.indexOf('Unauthenticated token') > -1) {
            //   if (window.parent === window) {
            //     setLoginVisible(true)
            //   } else {
            //     window.parent.nPopup.setLoginVisible(true)
            //   }
            //   return true
            // }
          } else if (code === 500 || message === 'Not authenticated') {
            // @todo : office addin에서 동작 중 문제가 발생하면, office email로 로그인 할 수 있도록 경로를 지정해야 함.
            reduxStore.dispatch({
              type: 'user/LOGOUT',
            })
            throw new Error(message)
          }
          return ret.ret
        })

        if (ret.retPromise != null) {
          return ret.retPromise
        }

        // if (isError === true) {
        //   const err = new ApolloError();
        //   err.code = error.code;
        //   err.errors = error.errors;
        //   throw err;
        // }
      }

      return responseData
    })
}

export async function login(email, password) {
  return Ngql.GQLObj(mutationLogin, {
    vars: {
      email,
      password,
      serverType: 'exchange',
      serverUri: 'mail.ninefolders.xyz',
    },
    useUI: false,
  })
    .mutate()
    .then((res) => {
      store.set('app.user.token', res.data.tokenAuth.token)
      store.set('app.user.refreshExpiresIn', res.data.tokenAuth.refreshExpiresIn)
      store.set('app.user.refreshToken', res.data.tokenAuth.refreshToken)

      return true
    })
    .catch((error) => {
      updateInfo.params = null
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

const queryUserInfo = Ngql.Query('userInfo', {
  // me: Ngql.Node({
  //   id: true,
  //   email: true,
  //   username: true,
  //   avatarUrl: true,
  //   ewsUri: true,
  //   authType: true,
  //   provider: true,
  //   protocol: true,
  //   version: true,
  //   createdAt: true,
  //   updatedAt: true,
  // }),
  // systemInfo: Ngql.Node({
  //   appName: true,
  //   description: true,
  //   adminEmail: true,
  //   version: true,
  //   locale: true,
  //   timezone: true,
  // }),
  adminEmail: true,
  serverType: true,
})

export async function register(/* email, password, name */) {
  function fakeRegister() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  return fakeRegister()
}

export async function currentAccount() {
  const token = store.get('app.user.token')

  if (token && token.length > 0) {
    return Ngql.GQLObj(queryUserInfo, {
      useUI: false,
    })
      .query()
      .then((res) => {
        console.log(' ---- ', res)

        setEnvParam('serverType', res.data.serverType)
        reduxStore.dispatch({
          type: 'menu/RELOAD',
        })

        if (res.data.adminEmail == null) {
          const tokenInfo = parseJwt(token)
          return {
            name: '',
            uid: '',
            email: tokenInfo.email,
            photoURL: '',
            role: 'admin',
            lastLogin: '',
            serverType: res.data.serverType,
          }
        }
        return {
          name: 'Administrator',
          uid: '',
          email: res.data.adminEmail,
          // photoURL: res.data.me.avatar,
          role: 'admin',
          lastLogin: '',
          serverType: res.data.serverType,
        }
      })
      .catch((error) => {
        if (error) {
          console.log(' ---- ', error)
          if (error.code !== 401) {
            notification.warning({
              message: error.code,
              description: error.message,
            })
          }
        }
      })
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(false)
    }, 200)
  })
}

export async function logout() {
  // return firebaseAuth()
  //   .signOut()
  //   .then(() => true)

  function fakeLogout() {
    store.remove('app.user.token')
    store.remove('app.user.refreshToken')
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  return fakeLogout()
}

const jwt = {
  customFetch,
  login,
  logout,
}

export default jwt
