const config = {
  defaultPage: '/dashboard',
  workspace: {
    paymentInfoEnable: false,
    downloadCSVEnable: true,
    editable: true,
    addWorksapceEnable: true,
    addMemberEnable: false,
    maxMemberEditable: true,
    expiredAtEditable: true,
    statusEditable: true,
  },
}

export default config
