import React from 'react'
import { Dropdown } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale,
})

const LanguageSwitcher = ({ dispatch, locale }) => {
  const changeLanguage = ({ key }) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
  }
  const language = locale.substr(0, 2)
  const menu = {
    selectedKeys: [locale],
    selectable: true,
    onClick: changeLanguage,
    items: [
      {
        key: 'ko-KR',
        label: (
          <React.Fragment>
            <span className="text-uppercase font-size-12 mr-2">KO</span>
            한국어
          </React.Fragment>
        ),
      },
      {
        key: 'en-US',
        label: (
          <React.Fragment>
            <span className="text-uppercase font-size-12 mr-2">EN</span>
            English
          </React.Fragment>
        ),
      },
      // Disable the laguages below - FR, RU, CN
      // {
      //   key: 'fr-FR',
      //   label: (
      //     <React.Fragment>
      //       <span className="text-uppercase font-size-12 mr-2">FR</span>
      //       French
      //     </React.Fragment>
      //   )
      // },
      // {
      //   key: 'ru-RU',
      //   label: (
      //     <React.Fragment>
      //       <span className="text-uppercase font-size-12 mr-2">RU</span>
      //       Русский
      //     </React.Fragment>
      //   )
      // },
      // {
      //   key: 'zh-CN',
      //   label: (
      //     <React.Fragment>
      //       <span className="text-uppercase font-size-12 mr-2">CN</span>
      //       简体中文
      //     </React.Fragment>
      //   )
      // },
    ],
  }

  return (
    <Dropdown menu={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <span className="text-uppercase">{language}</span>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(LanguageSwitcher)
