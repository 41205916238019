import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'
import { timeFormat } from 'utils'
// import 'moment/locale/en-us'
import 'moment/locale/fr'
import 'moment/locale/ko'
import 'moment/locale/ru'
import 'moment/locale/zh-cn'

import store from 'store'
import conf from './conf'

// const momentLocales = {
//   'en-US': 'en-us',
//   'fr-FR': 'fr',
//   'ko-KR': 'ko',
//   'ru-RU': 'ru',
//   'zh-CN': 'zh-CN',
// };

const hash =
  conf.gitInfo && conf.gitInfo.commit && conf.gitInfo.commit.hash
    ? conf.gitInfo.commit.hash
    : new Date().getTime()

const i18nOptions = {
  // saveMissing: true,
  fallbackLng: 'en-US',
  load: 'currentOnly',
  debug: conf.DEBUG === true,

  backend: {
    // for all available options read the backend's repository readme file
    loadPath: `/locales/{{lng}}/{{ns}}.json?cb=${hash}`,
  },

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value, format) => {
      console.log(' ------ ', value, format)
      if (value instanceof Date) return timeFormat(value, format)
      return value
    },
  },
}

let instance = i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)

if (conf.defaultLocale == null) {
  instance = instance
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
} else {
  const locale = store.get('app.settings.locale')
  if (locale == null) {
    i18nOptions.lng = conf.defaultLocale
  }
}

instance
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(i18nOptions)

i18n.on('languageChanged', lng => {
  moment.locale(lng)
  document.documentElement.lang = lng.substring(0, 2).toLowerCase()
})

export default i18n
