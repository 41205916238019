/* eslint-disable global-require */
import React from 'react'
// import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { getConfig } from 'env'

import { parseSearchParam, processNParam } from 'utils'

import Layout from 'layouts'

const mapStateToProps = ({ menu, user, settings }) => ({
  myRoutes: menu.routerData,
  routerAnimation: settings.routerAnimation,
  serverType: user.serverType,
})

@connect(mapStateToProps)
class Router extends React.Component {
  constructor(props) {
    super(props)
    // store.set('app.router.location', '');

    const { history } = props
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange)
    this.handleLocationChange(history.location)
  }

  // componentDidMount() {
  // }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory()
  }

  handleLocationChange = (e) => {
    const { history } = this.props
    console.log('ROUTE CHANGED', e, history)

    parseSearchParam()
    processNParam()
  }

  // const Router = ({ history, routerAnimation, serverType }) => {
  render = () => {
    const { history, routerAnimation, myRoutes } = this.props

    const defaultPage = getConfig('defaultPage')

    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Route
            render={(state) => {
              const { location } = state
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={location.pathname}
                    appear
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    <Switch location={location}>
                      <Route exact path="/" render={() => <Redirect to={defaultPage} />} />
                      {myRoutes.map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Component />
                              </div>
                            )
                          }}
                        />
                      ))}
                      <Redirect to="/auth/404" />
                    </Switch>
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          />
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default connect(mapStateToProps)(Router)
