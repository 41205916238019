import i18n from 'i18n'

export default function getMenuList() {
  const menuList = [
    {
      title: i18n.t('dashboard.title'),
      // title: 'Dashboard',
      key: 'dashboardnine',
      url: '/dashboard',
      icon: 'fe fe-home',
    },
    {
      title: i18n.t('workspace.title.list'),
      // title: 'Workspace List',
      key: 'workspaceList',
      url: '/workspace/list',
      icon: 'fe fe-layers',
    },
    {
      title: i18n.t('user.title.list'),
      // title: 'User List',
      key: 'userList',
      url: '/users/list',
      icon: 'icmn fe fe-users',
    },
    {
      title: i18n.t('accountinfo.title.list'),
      // title: 'User List',
      key: 'accountList',
      url: '/accountinfo/list',
      icon: 'icmn fe fe-mail',
    },
    {
      title: i18n.t('device.title.list'),
      // title: 'Workspace List',
      key: 'deviceList',
      url: '/device/list',
      icon: 'fe fe-smartphone',
    },
    {
      title: i18n.t('menu.preset'),
      // title: 'Preset List',
      key: 'presetMenu',
      // url: '/device/list',
      // icon: 'fe fe-smartphone',
      children: [
        {
          title: i18n.t('appConfig.title.list'),
          // title: 'Workspace List',
          key: 'appconfigList',
          url: '/appconfig/list?preset=true',
          icon: 'fe fe-settings',
        },
        {
          title: i18n.t('signature.title.list'),
          // title: 'Workspace List',
          key: 'signatureList',
          url: '/signature/list?preset=true',
          icon: 'fe fe-edit-3',
        },
        {
          title: i18n.t('push.title.list'),
          // title: 'Workspace List',
          key: 'pushList',
          url: '/push/list?preset=true',
          icon: 'fe fe-message-square',
        },
      ],
    },
    {
      title: i18n.t('menu.payment.title'),
      // title: 'Workspace List',
      key: 'paymentMenu',
      // url: '/payment/list',
      // icon: 'fe fe-dollar-sign',
      children: [
        {
          title: i18n.t('menu.payment.list'),
          // title: 'Workspace List',
          key: 'paymentList',
          url: '/payment/list',
          icon: 'fe fe-dollar-sign',
        },
        {
          title: i18n.t('menu.payment.history'),
          // title: 'Workspace List',
          key: 'paymentHistory',
          url: '/payment/history/list',
          icon: 'fe fe-book-open',
        },
        {
          title: i18n.t('redeem.title.list'),
          // title: 'Workspace List',
          key: 'redeemList',
          url: '/redeem/list',
          icon: 'fe fe-gift',
        },
      ],
    },
    {
      category: true,
      title: i18n.t('menu.category.enterpriseMaster'),
    },
    {
      title: i18n.t('autodiscover.title.list'),
      // title: 'Workspace List',
      key: 'autodiscoverList',
      url: '/autodiscover/list',
      icon: 'fe fe-link',
    },
    {
      title: i18n.t('enterpriseSite.title.list'),
      // title: 'Workspace List',
      key: 'enterpriseSite',
      url: '/enterprise-site/list',
      icon: 'fe fe-briefcase',
    },
    {
      title: i18n.t('statistics.title'),
      // title: 'Workspace List',
      key: 'statistics',
      url: '/statistics',
      icon: 'fe fe-monitor',
    },
    {
      divider: true,
    },
    {
      title: i18n.t('sysInfo.title.detail'),
      // title: 'Workspace List',
      key: 'systemInfo',
      url: '/systemInfo',
      icon: 'fe fe-monitor',
    },
    {
      title: i18n.t('log.title.logFiles'),
      // title: 'Workspace List',
      key: 'logFiles',
      url: '/logFiles',
      icon: 'fe fe-file-text',
    },
  ]

  return menuList
}
