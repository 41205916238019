import React from 'react'
import serverConfigFunc from 'server-config'
import loadable from '@loadable/component'
import { Spin } from 'antd'

const config = {
  fallback: <Spin />,
}

const defaultRoutes = [
  {
    path: '/auth/login_old',
    Component: loadable(() => import('pages/auth/login'), config),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: loadable(() => import('pages/auth/loginAdmin'), config),
    exact: true,
  },
  {
    path: '/auth/login2',
    Component: loadable(() => import('pages/auth/login2'), config),
    exact: true,
  },
  // // Auth Pages
  // {
  //   path: '/auth/login',
  //   Component: loadable(() => import('pages/auth/login'), config),
  //   exact: true,
  // },
  // {
  //   path: '/auth/forgot-password',
  //   Component: loadable(() => import('pages/auth/forgot-password'), config),
  //   exact: true,
  // },
  // {
  //   path: '/auth/register',
  //   Component: loadable(() => import('pages/auth/register'), config),
  //   exact: true,
  // },
  {
    path: '/auth/lockscreen',
    Component: loadable(() => import('pages/auth/lockscreen'), config),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: loadable(() => import('pages/auth/404'), config),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: loadable(() => import('pages/auth/500'), config),
    exact: true,
  },
]

export default function getRoutes() {
  const routerFunc = serverConfigFunc().router

  const myRoutes = [...defaultRoutes, ...routerFunc(config)]

  return myRoutes
}
