import React from 'react'
import JSONEditor from 'jsoneditor/dist/jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'

class JsonEditor extends React.PureComponent {
  timeout = undefined

  constructor(props) {
    super(props)
    this.state = {
      dirty: false,
    }
  }

  componentDidMount() {
    const { value, options, disabled } = this.props

    const mergedOptions = {
      ...options,
      onChange: this.handleChange,
      onValidationError: this.handleError,
    }

    this.initialMode = mergedOptions.mode || 'code'

    if (disabled === true) {
      mergedOptions.mode = 'preview'
    }

    this.editor = new JSONEditor(this.div, mergedOptions)

    this.setDisable(disabled)
    this.setValue(value)
  }

  componentDidUpdate(prevProps /* , prevState */) {
    const { onDirty, value, disabled } = this.props
    const { dirty, controllingFocus } = this.state

    if (dirty === true && controllingFocus) {
      onDirty()
    }

    if (dirty === true && !controllingFocus) {
      this.timeout = setTimeout(() => {
        this.setState({
          dirty: false,
        })
      }, 100)
    }

    if (controllingFocus) {
      clearTimeout(this.timeout)
    }

    if (prevProps.value !== value) {
      this.setValue(value)
      setTimeout(() => {
        this.setState({
          dirty: false,
        })
      }, 1)
    }

    if (prevProps.disabled !== disabled) {
      this.setDisable(disabled)
    }
  }

  componentWillUnmount() {
    this.editor.destroy()
    delete this.editor
    clearTimeout(this.timeout)
  }

  setDisable = (value) => {
    if (value === true) {
      this.editor.setMode('preview')
      this.editor.menu.style.display = 'none'
      this.editor.container.style.opacity = 0.5
      this.editor.container.style.cursor = 'not-allowed'
      this.editor.container.style.userSelect = 'none'
    } else {
      this.editor.setMode(this.initialMode)
      this.editor.menu.style.display = ''
      this.editor.container.style.opacity = null
      this.editor.container.style.cursor = ''
      this.editor.container.style.userSelect = ''
    }
  }

  setValue = (value) => {
    if (value != null && value.trim().length > 0) {
      if (typeof value === 'string') {
        if (value !== this.editor.getText()) {
          this.editor.setText(value)
        }
      } else {
        this.editor.set(value)
      }
    } else {
      this.editor.setText('{}')
    }
    if (this.div != null) {
      this.div.value = value
      this.div.setAttribute('value', value)
    }
  }

  getValue = () => {
    return this.editor.getText()
  }

  handleChange = () => {
    const { onChange } = this.props

    const value = this.editor.getText()

    console.log('validate : ', this.editor.validate())
    if (onChange != null) {
      onChange(value)
    }
    if (this.div != null) {
      this.div.value = value
      this.div.setAttribute('value', value)
    }
  }

  handleFocus = () => {
    this.setState({
      controllingFocus: true,
    })
  }

  handleBlur = () => {
    this.setState({
      controllingFocus: false,
    })
  }

  handleError = (e) => {
    const { onError } = this.props

    console.log('handleError : ', e)

    if (onError != null) {
      onError({
        hasError: e.length > 0,
        errors: e,
      })
    }
  }

  render() {
    const { className, style } = this.props

    return (
      <div
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        className={className}
        style={style}
        ref={(div) => {
          this.div = div
        }}
      />
    )
  }
}

JsonEditor.defaultProps = {
  config: {},
  onDirty: () => {},
}

export default JsonEditor
